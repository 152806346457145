import { FilterItem } from "./ItemList2";
import Checkbox from "~/ui/Checkbox/Checkbox";

interface ItemProps {
    label: string;
    item: FilterItem;
    isSelected: boolean;
    className?: string;
    onChange: (isSelected: boolean, item: FilterItem) => void;
}

const Item = (props: ItemProps) => {
    const { label, item, isSelected, className, onChange } = props;
    return (
        <Checkbox
            className={`filter-item flex gap-x-2 ${className}`}
            aria-labelledby={label}
            onChange={(isSelected) => onChange(isSelected, item)}
            isSelected={isSelected}
        >
            {label}
        </Checkbox>
    );
};

export default Item;
