import { create } from "zustand";
import { Image } from "~/features/Search/Results/ImageItem";

interface IFullDocumentItemProps {
    SourceDocumentID: number;
    SourceDocumentFilePath: string;
    SourceDocumentName: string;
    CompanyName: string;
    DocumentType: string;
    Category: string;
    StartPageNumber: number;
    Content: string;
    ThumbnailImage: string;
    Year: number;
    InCart: boolean;
}

interface IDetailsStore {
    index: number;
    id: null | number;
    resultType: string;
    title?: string;
    companyName?: string;
    fileName?: string;
    documentFileName?: string;
    metadata?: string;
    itemData?: string;
    content?: string;
    imgSrc?: string;
    documentSrc?: string;
    imageID?: null | number;
    imageGuid?: string;
    prevItem?: number;
    nextItem?: number;
    imagePreview?: Image | null;
    documentPreview?: IFullDocumentItemProps | null;
}

export const DetailsStore = create<IDetailsStore>(() => ({
    index: -1,
    id: null,
    resultType: "image",
    title: "",
    fileName: "",
    documentFileName: "",
    metadata: "",
    itemData: "",
    content: "",
    imgSrc: "",
    documentSrc: "",
    imageID: null,
    imageGuid: "",

    imagePreview: null,
    documentPreview: null,
}));
