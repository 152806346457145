import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReadLocalStorage } from "usehooks-ts";
import MessageBar from "~/components/MessageBar";
import SearchPanel from "~/features/SearchV2/SearchPanel";
import SearchResult from "~/features/Search/SearchResult";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";

const SearchPage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = useReadLocalStorage("_t");
    const { resetFilters, yearFilterReset } = React.useContext(AdvancedSearchContext);

    React.useEffect(() => {
        if (!token) {
            navigate("/auth/login");
        }
    }, [token, navigate]);

    React.useEffect(() => {
        if (location.search === "") {
            resetFilters();
            yearFilterReset();
        }
    }, [location]);

    return (
        <div className="flex h-full w-auto justify-center transition-all">
            {location.search !== "" ? <SearchResult /> : <SearchPanel />}
            <MessageBar />
        </div>
    );
};

export default SearchPage;
