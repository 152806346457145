import axios from "axios";
import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { useReadLocalStorage } from "usehooks-ts";
// import { DetailsStore } from "~/stores/DetailsStore";
import { LanguageContext } from "~/providers/LanguageProvider";
import { useTranslation } from "react-i18next";
import useAnalytics from "~/hooks/useAnalytics";
import { LabelTranslation } from "~/types";
import { ResultTypeIndex } from "~/features/Account/Bookmarks/types";
import { addBookmark, deleteBookmark } from "~/utils/bookmarkUtils";
import Button from "~/ui/Button/Button";
import Image from "~/ui/Image/Image";

interface IFullDocumentItemProps {
    fullDocument: FullDocument;
    storedDocuments: string;
    setFullDocuments: Dispatch<SetStateAction<string>>;
}

export interface FullDocument {
    documentID: number;
    documentFilePath: string;
    documentName: string;
    documentType: LabelTranslation;
    company: string;
    content: string;
    thumbnail: string;
    year: number;
}

const FullDocumentItem: React.FunctionComponent<IFullDocumentItemProps> = ({
    fullDocument,
    storedDocuments,
    setFullDocuments,
}) => {
    // const HOST = import.meta.env.VITE_API_HOST;
    const { language } = useContext(LanguageContext);
    const token = useReadLocalStorage("_t") as string;
    const { t } = useTranslation();
    const { sp_click } = useAnalytics();

    const [isInCart, setIsInCart] = useState(false);

    useEffect(() => {
        const bookmarks = storedDocuments !== "" ? JSON.parse(storedDocuments) : [];
        const match =
            bookmarks &&
            bookmarks.find(
                (bookmark: any) =>
                    bookmark.SourceDocumentID === fullDocument.documentID ||
                    bookmark.documentID === fullDocument.documentID
            );

        const inBookmark = !!match;
        setIsInCart(inBookmark);
    }, [storedDocuments]);

    const toggleCart = () => {
        // Update local storage
        const bookmarks = storedDocuments !== "" ? JSON.parse(storedDocuments) : [];
        const updatedBookmarks = isInCart
            ? bookmarks.filter((bookmark: FullDocument) => bookmark.documentID !== fullDocument.documentID)
            : [...bookmarks, fullDocument];
        setFullDocuments(JSON.stringify(updatedBookmarks));

        // Update server data
        isInCart
            ? deleteBookmark(fullDocument.documentID, ResultTypeIndex.FullDocument)
            : addBookmark(fullDocument.documentID, ResultTypeIndex.FullDocument);
        setIsInCart(!isInCart);

        if (!isInCart) {
            sp_click(
                "N",
                "bookmark",
                "Full Document bookmark",
                `${fullDocument.company} - ${fullDocument.documentType["en"]} ${fullDocument.year}`,
                {
                    _title: "Explore Full Document results",
                }
            );
        }
    };

    const downloadDocument = () => {
        sp_click(
            "N",
            "download",
            "Full Document download",
            `${fullDocument.company} - ${fullDocument.documentType["en"]} ${fullDocument.year}`,
            {
                _title: "Explore Full Document results",
            }
        );

        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/download/document/id?documentID=${fullDocument.documentID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", fullDocument.documentName || "pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const previewDocument = () => {
        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/download/document/id?documentID=${fullDocument.documentID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    return (
        <div className="rounded-lg shadow-sm p-4 bg-white w-full flex">
            <div
                className="flex items-center text-left flex-grow"
                // onPress={() => setDetailsData(page)}
            >
                <Image
                    src={`${import.meta.env.VITE_API_HOST}${fullDocument.thumbnail}`}
                    alt={`${fullDocument.company} ${fullDocument.documentType[language]}`}
                    className="w-[248px]"
                />

                <div className="flex-1 px-4 2xl:px-8 py-4 h-full flex flex-col justify-between">
                    <div>
                        <h2>{fullDocument.company}</h2>
                        <h4>
                            {fullDocument.documentType[language]}, {fullDocument.year}
                        </h4>
                    </div>

                    <p className="text-sm text-foreground-light">{fullDocument.content.substring(0, 300)}</p>

                    <div className="flex mt-4">
                        <Button color="primary" icon="download" className="w-32 2xl:w-44" onPress={downloadDocument}>
                            {t("download")}
                        </Button>
                        <Button color="light" icon="image" className="w-32 2xl:w-44 ml-4" onPress={previewDocument}>
                            {t("preview")}
                        </Button>
                    </div>
                </div>
            </div>

            <Button
                icon="favorite"
                iconFilled
                color="secondary"
                elevated
                noHover
                className={`${isInCart ? "!text-primary" : ""}`}
                onPress={toggleCart}
            />
        </div>
    );
};

export default FullDocumentItem;
