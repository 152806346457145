import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Search from "~/ui/Icon/Search";
import Button from "~/ui/Button/Button";

interface AdvancedSearchFilterBarProps {
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    clearFn?: () => void;
    bgColor?: string;
    className?: string;
    placeholder?: string;
}

const AdvancedSearchFilterBar = ({
    search,
    setSearch,
    clearFn,
    bgColor = "bg-white",
    className,
    placeholder = "",
}: AdvancedSearchFilterBarProps) => {
    const { t } = useTranslation();
    return (
        <div className={`w-full flex flex-inline gap-2 sticky top-0 z-10 border-b-[1px] border-hr-light ${className}`}>
            <div className={`relative flex gap-x-2 w-full rounded-md m-3 ${bgColor}`}>
                <input
                    className="filter-input"
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={placeholder}
                />
                <Search className="absolute top-[50%] -translate-y-1/2 right-[12px] text-[#C6C6C6]" />
            </div>
            {clearFn && (
                <Button color="default" size="sm" icon="delete" className="!rounded-md text-xs" onPress={clearFn}>
                    {t("clearSelected")}
                </Button>
            )}
        </div>
    );
};

export default AdvancedSearchFilterBar;
