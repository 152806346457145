import { Suspense, ImgHTMLAttributes } from "react";
import ImgPlaceholder from "~/assets/search/no-image.jpg";

const Image = (props: ImgHTMLAttributes<HTMLImageElement>) => {
    const handleError = (event:any) => {
        event.target.src = ImgPlaceholder;
        event.onerror = null;
    };

    return (
        <Suspense fallback={<ImagePlaceholder />}>
            <img 
                {...props}
                onError={handleError}
            />
        </Suspense>
    );
};

const ImagePlaceholder = () => {
  return <img
            src={ImgPlaceholder}
            alt="no-image"
            width="248px"
            height="auto"
        />
};

export default Image;