import { useState, useEffect, useContext, FunctionComponent, Dispatch, SetStateAction, useMemo } from "react";
import { LanguageContext } from "~/providers/LanguageProvider";
import { DetailsStore } from "~/stores/DetailsStore";
import useAnalytics from "~/hooks/useAnalytics";
import { addBookmark, deleteBookmark } from "~/utils/bookmarkUtils";
import { ResultTypeIndex } from "~/features/Account/Bookmarks/types";
import { LabelTranslation } from "~/types";
import Button from "~/ui/Button/Button";

interface IImageItemProps {
    index: number;
    item: Image;
    images: string;
    setImages: Dispatch<SetStateAction<string>>;
}

export interface Image {
    imageID: number;
    imageGuid: string;
    imageFilePath: string;
    imageFilename: string;
    pdfFilePath: string;
    company: string;
    year: number;
    documentGuid: string;
    documentType: LabelTranslation;
    metadata: LabelTranslation;
    width: number;
    height: number;
}

const ImageItem: FunctionComponent<IImageItemProps> = ({ item, images, setImages }) => {
    const { language } = useContext(LanguageContext);
    const [isInCart, setIsInCart] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const { sp_click } = useAnalytics();

    useEffect(() => {
        const bookmarks = images && images !== "" ? JSON.parse(images) : [];

        const match = bookmarks.find((bookmark: Image) => bookmark.imageID === item.imageID);
        const inBookmark = !!match;

        setIsInCart(inBookmark);
        setIsFavorite(inBookmark);
    }, [images]);

    const toggleFavorite = () => {
        // Update local storage
        const bookmarks = images !== "" ? JSON.parse(images) : [];
        const updatedBookmarks = isInCart
            ? bookmarks.filter((bookmark: Image) => bookmark.imageID !== item.imageID)
            : [...bookmarks, item];
        setImages(JSON.stringify(updatedBookmarks));

        // Update server data
        isFavorite
            ? deleteBookmark(item.imageID, ResultTypeIndex.Image)
            : addBookmark(item.imageID, ResultTypeIndex.Image);
        setIsFavorite(!isFavorite);

        if (!isFavorite) {
            sp_click(
                "N",
                "bookmark",
                "Image bookmark",
                `${item.company} - ${item.documentType["en"]} ${item.year}, ${item.metadata["en"]}`,
                {
                    _title: "Explore Image results",
                }
            );
        }
    };

    const itemComputedHeight = useMemo(() => {
        const windowWidth = window.innerWidth;
        const newWidth = windowWidth * 0.25;
        const computedHeight = (item.height / item.width) * newWidth;
        return computedHeight;
    }, [window.innerWidth]);

    return (
        <div
            className="w-full rounded-2xl bg-white overflow-hidden text-left cursor-pointer relative"
            key={item.documentGuid}
            onClick={() => DetailsStore.setState((state) => ({ ...state, imagePreview: item }))}
            style={{
                minHeight: itemComputedHeight,
            }}
        >
            <div className="rounded-xl m-2 overflow-hidden">
                <img src={`${import.meta.env.VITE_API_HOST}${item.imageFilePath}`} alt="" className="w-full" />
            </div>
            <div className="w-full p-6 pt-0 flex gap-2.5">
                <div className="flex-grow">
                    <h2>{item.company}</h2>
                    <h4 className="leading-none">
                        {item.documentType[language]}, {item.year}
                    </h4>
                    <p className="text-xs text-ellipsis">{item.metadata[language]}</p>
                </div>
                <Button
                    icon="favorite"
                    iconFilled
                    color="light"
                    size="md"
                    noHover
                    className={`focus-outline:none !bg-transparent hover:text-foreground-dark ${
                        isInCart && "!text-primary"
                    }`}
                    onPress={toggleFavorite}
                />
            </div>
            {/* <div className="absolute top-2 right-2 bg-yellow-300 text-black font-black h-8 w-8 rounded-full flex justify-center items-center text-center drop-shadow-xl m-auto">
                {index}
            </div> */}
        </div>
    );
};

export default ImageItem;
