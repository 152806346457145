import { ReactElement } from "react";
import { Popover } from "@headlessui/react";
import ChevronDown from "~/ui/Icon/ChevronDown";

interface FilterToggleProps {
    label: string;
    count: number | string;
    children: (props: { open: boolean }) => ReactElement;
}

const FilterToggle = (props: FilterToggleProps) => {
    const { label, count, children } = props;

    return (
        <Popover className="filter-toggle">
            {({ open }) => (
                <>
                    <Popover.Button className="filter-btn">
                        {label}
                        {typeof count === "number" && count > 0 && <div className="counter">{count}</div>}
                        <ChevronDown className={`text-inherit ${open ? "rotate-180 transform" : ""}`} />
                    </Popover.Button>

                    <Popover.Panel unmount={false} className="absolute filter-popup">
                        {children({ open })}
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};

export default FilterToggle;
