import { Dispatch, SetStateAction, useState, useEffect, useContext, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FilterType } from "~/providers/AdvancedSearchProvider";
import { LanguageContext, SupportedLanguages } from "~/providers/LanguageProvider";
import Checkbox from "~/ui/Checkbox/Checkbox";
import Button from "~/ui/Button/Button";
import Loader from "~/ui/Loader";
import Icon from "~/ui/Icon/Icon";
import Item from "./Item";

export type LabelTranslation = {
    [lang in SupportedLanguages]: string;
};

export interface MetaDataItem extends FilterType {
    documentTypeId: number;
    categoryId: number;
}

export interface Item {
    id: number;
    label: LabelTranslation;
    items: Item[] | MetaDataItem[];
}

interface ItemListProps {
    label: string;
    items: Item[];
    listItems: FilterType[];
    setListItems: Dispatch<SetStateAction<any[]>>;
    enableSelectAll?: boolean;
    enableReset?: boolean;
    enableUpdate?: boolean;
    groupSelected?: boolean;
    reset: boolean;
    resetFn: () => void;
    updateFn: () => void;
    autoUpdate?: boolean;
    isLoading?: boolean;
}

const ExpandableList = (props: ItemListProps) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const {
        label,
        items,
        listItems,
        setListItems,
        updateFn,
        enableUpdate = true,
        enableSelectAll = false,
        enableReset = true,
        groupSelected = true,
        autoUpdate = false,
        isLoading = false,
        reset,
        resetFn,
    } = props;
    const [metadataItems, setMetaDataItems] = useState<any[]>([]);
    const listEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (items) {
            const mdItems = items.flatMap((meta: Item) => {
                return meta.items.flatMap((list: any) => list.items);
            });
            setMetaDataItems(mdItems);
        }
    }, [items]);

    const onSelectAll = (isSelected: boolean) => {
        if (isSelected) {
            setListItems(metadataItems);
        } else {
            setListItems([]);
        }
    };

    const onSelection = (isSelected: boolean, item: any) => {
        if (isSelected) {
            setListItems((prevItems) => [...prevItems, item]);
        } else {
            setListItems(listItems.filter((x) => x.id !== item.id));
        }
    };
    const checkSelection = (id: number) => {
        return listItems.find((x) => x.id === id) !== undefined;
    };

    const clearSelections = () => {
        setListItems([]);
    };

    useEffect(() => {
        if (enableReset && reset) {
            // Reset function when popup closes
            resetFn();
        } else {
            // Reset list scroll position when popup opens
            if (listEl.current) listEl.current.scrollTop = 0;
        }
    }, [reset, listEl]);

    useEffect(() => {
        if (autoUpdate) {
            updateFn();
        }
    }, [listItems]);

    const ClearBtn = () => {
        return (
            <button className="flex items-center text-xs text-primary -mt-1" onClick={clearSelections}>
                {t("clear")}
                <Icon className="!text-primary mt-[1px] px-1">close</Icon>
            </button>
        );
    };

    const UpdateBtn = () => (
        <Button className="mt-2" fullWidth color="primary" isDisabled={!enableUpdate} onPress={updateFn}>
            {t("update")}
        </Button>
    );

    return isLoading ? (
        <Loader className="scale-50" />
    ) : (
        <>
            <div ref={listEl} className="grow max-w-[327px] overflow-auto">
                <div className="filter-item-list">
                    {enableSelectAll && (
                        <Checkbox
                            className="flex gap-x-2 !text-[#718093] checkbox-outline"
                            aria-labelledby="All Disclosures"
                            onChange={(isSelected) => onSelectAll(isSelected)}
                            isSelected={metadataItems.length === listItems.length}
                        >
                            {t("selectAll")}
                        </Checkbox>
                    )}
                    {groupSelected && listItems.length > 0 && (
                        <>
                            <div className="flex justify-between items-center py-3 px-2">
                                <div className="filter-title">
                                    <Trans i18nKey="selectedCategory">Selected {{ category: label }}</Trans>
                                </div>
                                {enableReset && <ClearBtn />}
                            </div>
                            {listItems.map((item: any, i: number) => (
                                <Item
                                    key={i}
                                    label={language === "en" ? item.name : item.frName}
                                    item={item}
                                    onChange={(isSelected) => onSelection(isSelected, item)}
                                    isSelected={checkSelection(Number(item.id))}
                                />
                            ))}
                        </>
                    )}
                    {items.map((disclosure: Item, i: number) => (
                        <div key={i} className="flex flex-col">
                            <div className="filter-title p-2 mt-1 !text-gray-900">{disclosure.label[language]}</div>
                            {disclosure.items.map((category: any, j: number) => {
                                return (
                                    <div className="sub-list flex flex-col gap-y-2 mt-2" key={j}>
                                        <div className="filter-title pl-4 mb-2">{category.label[language]}</div>
                                        <div>
                                            {category.items.map((metadata: MetaDataItem, k: number) => {
                                                const name = language === "en" ? metadata.name : metadata.frName;
                                                if (
                                                    (groupSelected && !checkSelection(Number(metadata.id))) ||
                                                    !groupSelected
                                                ) {
                                                    return (
                                                        <Item
                                                            key={k}
                                                            label={name}
                                                            item={metadata}
                                                            className="!pl-6"
                                                            onChange={(isSelected) => onSelection(isSelected, metadata)}
                                                            isSelected={checkSelection(Number(metadata.id))}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            {!autoUpdate && (
                <div className="w-full p-2 pt-0">
                    <UpdateBtn />
                </div>
            )}
        </>
    );
};

export default ExpandableList;
