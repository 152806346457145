import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import Button from "~/ui/Button/Button";

const FilterKeyword = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { searchTerm, setSearchTerm } = useContext(AdvancedSearchContext);
    const [textValue, setTextValue] = useState(searchTerm);
    const showSearchButton = location.pathname !== "/search";

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
    };

    const search = () => {
        if (textValue) setSearchTerm(textValue);
    };

    // useEffect(() => {
    //     const timerId = setTimeout(() => {
    //         setSearchTerm(textValue);
    //     }, 500);

    //     return () => {
    //         clearTimeout(timerId);
    //     };
    // }, [textValue]);

    useEffect(() => {
        console.log(location.pathname, location.search);
    }, []);

    return (
        <form className="relative flex grow p-1" onSubmit={(e) => e.preventDefault()}>
            <input
                type="text"
                placeholder={`${t("typeKeywordMsg")}`}
                className="pl-2 flex-grow focus-visible:outline-none bg-transparent text-black"
                value={textValue}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && showSearchButton) search();
                }}
                required
            />
            {showSearchButton && (
                <Button
                    type="submit"
                    icon="search"
                    size="base"
                    color="light"
                    noHover
                    className="absolute right-[5px] top-2 bg-transparent !text-primary outline-none"
                    onPress={search}
                />
            )}
        </form>
    );
};

export default FilterKeyword;
