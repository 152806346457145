import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import { ResultType } from "../Search/Results/types";

const SearchTypeTabs = () => {
    const { activeTabs, setActiveTabs } = useContext(AdvancedSearchContext);
    const { t } = useTranslation();
    const REGION = import.meta.env.VITE_REGION;
    const tabs: ResultType[] =
        REGION === "us"
            ? ["images", "texts", "pages", "marketIntelligence"]
            : ["images", "pages", "fullDocuments", "marketIntelligence"];

    const toggleActiveTabs = (tab: ResultType) => {
        setActiveTabs([tab]);
    };

    const isActiveTab = (tab: ResultType) => activeTabs.includes(tab);

    useEffect(() => {
        setActiveTabs([tabs[0]]);
    }, []);

    return (
        <div className="relative h-[56px]">
            <div className="relative h-full flex row gap-x-1 z-20 justify-center items-center p-1">
                {tabs.map((tab: ResultType, i) => (
                    <button
                        key={i}
                        onClick={() => toggleActiveTabs(tab)}
                        className={`rounded-full px-8 h-full ${
                            isActiveTab(tab) ? "bg-orange border-primary text-white" : "text-[#4B4B4B]"
                        }`}
                    >
                        {t(`searchResultTypes.${tab}`)}
                    </button>
                ))}
            </div>
            <div className="absolute w-full h-full bg-white opacity-60 rounded-full z-0 top-0" />
        </div>
    );
};

export default SearchTypeTabs;
