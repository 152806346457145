import { useState, useRef, useContext, useEffect, FunctionComponent, Fragment } from "react";
// import { Button } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import { ResultType } from "../Search/Results/types";
import TextResults from "./Results/Text";
import ImageResults from "./Results/Image";
import PagesResults from "./Results/PagesResults";
import DetailsModal from "./Results/DetailsModal";
import FullDocument from "./Results/FullDocument";
import DocumentViewer from "./Results/DocumentViewer";
// import FilterIcon from "~/ui/Icon/FilterIcon";
import FilterList from "./Filters/ListToggle/FilterList";
import useSolrSearch from "~/hooks/useSolrSearch";
import ExploreLogo from "~/components/ExploreLogo";
import LanguageSwitcher from "~/components/LanguageSwitcher";
import Dashboard from "../Dashboard";

const SearchResult: FunctionComponent = () => {
    const { t } = useTranslation();
    const REGION = import.meta.env.VITE_REGION;

    const { activeTabs } = useContext(AdvancedSearchContext);
    // const [showFilters, setShowFilters] = useState(true);

    const { logSearch } = useSolrSearch("");

    const resultsEl = useRef<HTMLDivElement>(null);
    const resultType: ResultType[] =
        REGION === "us" ? ["images", "texts", "pages"] : ["images", "pages", "fullDocuments"];

    const defaultTab = () => {
        return {
            index: resultType.findIndex((tab) => tab === activeTabs[0]),
            type: activeTabs[0],
        };
    };
    const [type, setType] = useState(defaultTab().type);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        setSelectedIndex(defaultTab().index);
    }, [activeTabs]);

    useEffect(() => {
        logSearch();
    }, []);

    return (
        <div className="h-full w-full">
            <Tab.Group
                as="div"
                className="flex flex-col flex-grow z-[5] relative h-full max-h-screen overflow-auto"
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
            >
                <div className="w-full mx-auto shrink-0 flex flex-col items-center text-foreground-dark relative z-30">
                    <div className="w-full bg-white py-3 px-8 flex justify-between items-center relative z-30">
                        <ExploreLogo />

                        <Tab.List className="relative h-[52px]">
                            <div className="relative h-full flex row gap-x-1 z-20 justify-center items-center p-1">
                                {resultType.map((type) => (
                                    <Tab key={type} as={Fragment}>
                                        {({ selected }) => (
                                            <button
                                                onClick={() => setType(type)}
                                                className={`rounded-full px-4 h-full ${
                                                    selected
                                                        ? "bg-orange border-primary text-white outline-none"
                                                        : "text-[#4B4B4B]"
                                                }`}
                                            >
                                                {t(`searchResultTypes.${type}`)}
                                            </button>
                                        )}
                                    </Tab>
                                ))}
                            </div>

                            {/* <div className="w-auto border-l h-12 flex flex-grow items-center justify-end px-4">
                            <Button
                                className={`flex items-center hover:text-primary ${showFilters && "text-primary"}`}
                                onPress={() => setShowFilters(!showFilters)}
                            >
                                <FilterIcon />
                                <span className="ml-2 title-md">{t("filters")}</span>
                            </Button>
                        </div> */}
                        </Tab.List>

                        <div className="flex flex-row">
                            <LanguageSwitcher />
                            <Dashboard />
                        </div>
                    </div>
                    <FilterList resultType={type} show={true} />
                </div>

                <Tab.Panels ref={resultsEl} className="h-full overflow-hidden">
                    <Tab.Panel className="w-full h-full">
                        <ImageResults />
                    </Tab.Panel>

                    {REGION === "us" && (
                        <Tab.Panel className="w-full h-full">
                            <TextResults />
                        </Tab.Panel>
                    )}

                    <Tab.Panel className="w-full h-full">
                        <PagesResults />
                    </Tab.Panel>

                    {REGION === "fr" && (
                        <Tab.Panel className="w-full h-full">
                            <FullDocument />
                        </Tab.Panel>
                    )}
                </Tab.Panels>
            </Tab.Group>

            <DetailsModal />
            <DocumentViewer />
        </div>
    );
};

export default SearchResult;
