const ChevronRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70005 17.3C8.51672 17.1167 8.42505 16.8833 8.42505 16.6C8.42505 16.3167 8.51672 16.0833 8.70005 15.9L12.6 12L8.70005 8.09999C8.51672 7.91665 8.42505 7.68332 8.42505 7.39999C8.42505 7.11665 8.51672 6.88332 8.70005 6.69999C8.88338 6.51665 9.11672 6.42499 9.40005 6.42499C9.68338 6.42499 9.91672 6.51665 10.1 6.69999L14.7 11.3C14.8 11.4 14.8709 11.5083 14.9125 11.625C14.9542 11.7417 14.975 11.8667 14.975 12C14.975 12.1333 14.9542 12.2583 14.9125 12.375C14.8709 12.4917 14.8 12.6 14.7 12.7L10.1 17.3C9.91672 17.4833 9.68338 17.575 9.40005 17.575C9.11672 17.575 8.88338 17.4833 8.70005 17.3Z"
        fill="#4D4B56"
      />
    </svg>
  );
};

export default ChevronRight;
