import { useState, useEffect } from "react";
import i18next from "i18next";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { fetcher } from "~/hooks/useFetcher";

export interface ResponseData {
    id: number;
    name: string;
    frName: string;
    count: number;
}

const useDisclosures = () => {
    const token = useReadLocalStorage("_t") as string;
    const { language } = i18next;
    const culture = language === "en" ? "en-US" : "fr-FR";
    const fetchUrl = `/api/disclosures?LanguageCode=${culture}`;
    const { data, isLoading } = useSWR(fetchUrl, (url) => fetcher(url, token));
    const [disclosures, setDisclosures] = useState([]);

    useEffect(() => {
        if (data) {
            setDisclosures(
                data.map((item: ResponseData) => {
                    return {
                        id: item.id,
                        name: item.name,
                        frName: item.frName ? item.frName : item.name,
                    };
                })
            );
        }
    }, [data]);

    return {
        disclosures,
        isLoading,
    };
};

export default useDisclosures;
