import { useContext } from "react";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import Companies from "../Search/Filters/Categories/Companies";
import Disclosures from "../Search/Filters/Categories/Disclosures";
import ImageThemes from "../Search/Filters/Categories/ImageThemes";
import Industries from "../Search/Filters/Categories/Industries";
import PageSections from "../Search/Filters/Categories/PageSections";
import Years from "../Search/Filters/Categories/Years";
import FilterKeyword from "./FilterKeyword";
import SearchButton from "./SearchButton";
import Categories from "../MarketIntelligence/Categories";

interface FilterListProps {
    resultType: string;
}

const FilterList = (props: FilterListProps) => {
    const { resultType } = props;
    const { searchTerm } = useContext(AdvancedSearchContext);
    const hasTextbox = resultType === "texts";

    return resultType === "marketIntelligence" ? (
        <Categories />
    ) : (
        <div className={`filter-grp ${hasTextbox && "flex-col-reverse"}`}>
            <div className="filter-list">
                <Disclosures />
                {resultType === "images" && <ImageThemes />}
                {resultType === "pages" && <PageSections />}
                <Years />
                <Industries />
                <Companies />
            </div>

            {hasTextbox ? (
                <div className="w-full px-2">
                    <form className="flex border-b-[1px] mb-2 pb-2" onSubmit={(e) => e.preventDefault()}>
                        <FilterKeyword />
                        <SearchButton className="!mr-0" disabled={!searchTerm} />
                    </form>
                </div>
            ) : (
                <SearchButton disabled={false} />
            )}
        </div>
    );
};

export default FilterList;
