import { useState, useEffect } from "react";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { fetcher } from "~/hooks/useFetcher";

const useYears = () => {
    const token = useReadLocalStorage("_t") as string;
    const { data, isLoading } = useSWR("/api/search/filter/year", (url) => fetcher(url, token));
    const [years, setYears] = useState([]);

    useEffect(() => {
        if (data) {
            setYears(
                data.map((item: number) => {
                    return {
                        id: item,
                        name: item,
                        frName: item,
                    };
                })
            );
        }
    }, [data]);

    return {
        years,
        isLoading,
    };
};

export default useYears;
