import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FilterType } from "~/providers/AdvancedSearchProvider";
import { LanguageContext } from "~/providers/LanguageProvider";
import Button from "~/ui/Button/Button";
import Icon from "~/ui/Icon/Icon";
import Checkbox from "~/ui/Checkbox/Checkbox";
import Loader from "~/ui/Loader";
import Item from "./Item";

export type FilterItem = FilterType;

interface ItemListProps {
    label: string;
    items: FilterItem[];
    listItems: FilterItem[];
    setListItems: Dispatch<SetStateAction<any[]>>;
    enableSelectAll?: boolean;
    enableReset?: boolean;
    reset: boolean;
    resetFn: () => void;
    enableUpdate?: boolean;
    updateFn: () => void;
    groupSelected?: boolean;
    autoUpdate?: boolean;
    isLoading?: boolean;
    allowNoSelection?: boolean;
}

const ItemList2 = (props: ItemListProps) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const {
        label,
        items,
        listItems,
        setListItems,
        enableUpdate = true,
        updateFn,
        enableSelectAll = false,
        enableReset = false,
        groupSelected = false,
        autoUpdate = false,
        allowNoSelection = true,
        isLoading = false,
        reset,
        resetFn,
    } = props;
    const listEl = useRef<HTMLDivElement>(null);

    const onSelectAll = (isSelected: boolean) => {
        if (isSelected) {
            setListItems(items);
        } else {
            setListItems([]);
        }
    };

    const onSelection = (isSelected: boolean, item: FilterItem) => {
        if (isSelected) {
            setListItems((prevItems) => [...prevItems, item]);
        } else {
            if (allowNoSelection || (!allowNoSelection && listItems.length > 1)) {
                setListItems(listItems.filter((x) => x.id !== item.id));
            }
        }
    };

    const checkSelection = (id: number) => {
        return listItems.find((x) => x.id === id) !== undefined;
    };

    const clearSelections = () => {
        setListItems([]);
    };

    useEffect(() => {
        if (enableReset && reset) {
            // Reset function when popup closes
            resetFn();
        } else {
            // Reset list scroll position when popup opens
            if (listEl.current) listEl.current.scrollTop = 0;
        }
    }, [reset, listEl]);

    useEffect(() => {
        if (autoUpdate) {
            updateFn();
        }
    }, [listItems]);

    const ClearBtn = () => (
        <button
            className={`flex items-center text-xs text-primary ${listItems.length === 0 && "invisible"}`}
            onClick={clearSelections}
        >
            {t("clear")}
            <Icon className="!text-primary mt-[1px] px-1">close</Icon>
        </button>
    );

    const UpdateBtn = () => (
        <Button className="mt-2" fullWidth color="primary" isDisabled={!enableUpdate} onPress={updateFn}>
            {t("update")}
        </Button>
    );

    return isLoading ? (
        <Loader className="scale-50" />
    ) : (
        <>
            <div ref={listEl} className="grow max-w-[327px] overflow-auto">
                <div className="filter-item-list">
                    {enableSelectAll && (
                        <Checkbox
                            className="flex gap-x-2 !text-[#718093] checkbox-outline"
                            aria-labelledby="All Disclosures"
                            onChange={(isSelected) => onSelectAll(isSelected)}
                            isSelected={items.length === listItems.length}
                        >
                            {t("selectAll")}
                        </Checkbox>
                    )}

                    {groupSelected && listItems.length > 0 && (
                        <>
                            <div className="flex justify-between items-center py-3 px-2">
                                <div className="filter-title">
                                    <Trans i18nKey="selectedCategory">Selected {{ category: label }}</Trans>
                                </div>
                                {enableReset && <ClearBtn />}
                            </div>
                            {listItems.map((item: any, i: number) => (
                                <Item
                                    key={i}
                                    label={language === "en" ? item.name : item.frName}
                                    item={item}
                                    onChange={(isSelected) => onSelection(isSelected, item)}
                                    isSelected={checkSelection(item.id)}
                                />
                            ))}
                        </>
                    )}

                    <div className="flex justify-between items-center py-3 px-2">
                        <div className="filter-title">{label}</div>
                        {enableReset && !groupSelected && <ClearBtn />}
                    </div>
                    {items.map((item: any, i: number) => {
                        if ((groupSelected && !checkSelection(item.id)) || !groupSelected) {
                            return (
                                <Item
                                    key={i}
                                    label={language === "en" ? item.name : item.frName}
                                    item={item}
                                    onChange={(isSelected) => onSelection(isSelected, item)}
                                    isSelected={checkSelection(item.id)}
                                />
                            );
                        }
                    })}
                </div>
            </div>
            {!autoUpdate && (
                <div className="w-full p-2 pt-0">
                    <UpdateBtn />
                </div>
            )}
        </>
    );
};

export default ItemList2;
