import { useEffect, useContext } from "react";
import useSolrSearch from "~/hooks/useSolrSearch";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import { AnimatePresence, motion } from "framer-motion";
import Companies from "../Categories/Companies";
import Disclosures from "../Categories/Disclosures";
import ImageThemes from "../Categories/ImageThemes";
import Industries from "../Categories/Industries";
import PageSections from "../Categories/PageSections";
import Years from "../Categories/Years";
// import SortBy from "../Categories/SortBy";
import FilterKeyword from "./FilterKeyword";

interface FilterListProps {
    resultType: string;
    show: boolean;
}

const FilterList = (props: FilterListProps) => {
    const { resultType, show } = props;
    const { triggerSearch } = useContext(AdvancedSearchContext);
    const { logSearch } = useSolrSearch("");

    useEffect(() => {
        if (triggerSearch) logSearch();
    }, [triggerSearch]);

    return (
        <div className="filter-group filter-bar w-full bg-surface p-2 rounded-b-3xl drop-shadow-md">
            <AnimatePresence>
                {show && (
                    <motion.div
                        // className="w-full z-20 bg-surface border border-soft px-4 h-12 left-0 flex items-center justify-center gap-6 drop-shadow-md"
                        className="filter-list"
                        initial={{ marginTop: "-3rem" }}
                        animate={{ marginTop: 0 }}
                        exit={{ marginTop: "-3rem" }}
                        transition={{
                            ease: "easeOut",
                        }}
                    >
                        {resultType === "texts" && <FilterKeyword />}
                        <Disclosures />
                        {resultType === "images" && <ImageThemes />}
                        {resultType === "pages" && <PageSections />}
                        <Years />
                        <Industries />
                        <Companies />
                        {/* <SortBy /> */}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FilterList;
