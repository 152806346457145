import "./index.css";

interface LoaderProps {
    className?: string;
}

const Loader = ({ className }: LoaderProps) => {
    return (
        <div className={`w-full h-full flex justify-center items-center ${className}`}>
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
