import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDisclosures from "~/hooks/useDisclosures";
import { AdvancedSearchContext, FilterType } from "~/providers/AdvancedSearchProvider";
import FilterToggle from "../ListToggle/FilterToggle";
import ItemList2 from "../ListLayout/ItemList2";

const Disclosures = () => {
    const FILTER_TYPE = "disclosure";
    const { t } = useTranslation();
    const { disclosures, isLoading } = useDisclosures();
    const { disclosureFilters, setDisclosureFilters, filters, setFilters, addFilter, setTriggerSearch } =
        useContext(AdvancedSearchContext);

    // Temp list for changes before update event
    const [tempList, setTempList] = useState<FilterType[]>(disclosureFilters);

    const enableUpdate =
        tempList.length > 0 &&
        (disclosureFilters.length !== tempList.length ||
            !disclosureFilters.every((f) => tempList.find((tf) => tf.id === f.id)));

    const handleUpdate = () => {
        // Update context data when update button is clicked
        if (tempList.length > 0) {
            setDisclosureFilters(tempList);

            // Remove items from filters that are not in tempList
            const currFilters = filters.filter((item) =>
                tempList.some(
                    (tempItem) => (tempItem.id === item.id && item.type === FILTER_TYPE) || item.type !== FILTER_TYPE
                )
            );
            setFilters(currFilters);

            tempList.forEach((d: FilterType) => {
                addFilter(d, FILTER_TYPE);
            });
        } else {
            setDisclosureFilters([]);
            setFilters(filters.filter((f) => f.type !== FILTER_TYPE));
        }

        setTriggerSearch(true);

        // else {
        //     setDisclosureFilters(disclosures);
        //     setTempList(disclosures);
        //     disclosures.forEach((d: FilterType) => {
        //         addFilter(d, FILTER_TYPE);
        //     });
        // }
    };

    const resetItems = () => setTempList(disclosureFilters);

    useEffect(() => {
        if (disclosureFilters.length !== tempList.length) setTempList(disclosureFilters);
    }, [disclosureFilters]);

    return (
        <FilterToggle label={t("disclosure")} count={tempList.length}>
            {({ open }) => (
                <ItemList2
                    label={t("disclosures")}
                    items={disclosures}
                    listItems={tempList}
                    setListItems={setTempList}
                    reset={!open}
                    resetFn={resetItems}
                    enableUpdate={enableUpdate}
                    updateFn={handleUpdate}
                    autoUpdate={true}
                    isLoading={isLoading}
                />
            )}
        </FilterToggle>
    );
};

export default Disclosures;
