import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { fetcher } from "~/hooks/useFetcher";
import { FilterType } from "~/providers/AdvancedSearchProvider";

export interface CompanyType {
    CompanyId: number;
    Name: string;
    Rank: string;
}

const useCompany = () => {
    const token = useReadLocalStorage("_t") as string;
    const fetchUrl = "/api/search/filter/company";
    const { data: allCompanies, isLoading } = useSWR(fetchUrl, (url) => fetcher(url, token));
    const [companies, setCompanies] = useState<FilterType[]>([]);
    const [filteredCompanies, setFilteredCompanies] = useState<FilterType[]>(allCompanies);
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (allCompanies) {
            setCompanies(
                allCompanies.map((item: CompanyType) => {
                    return {
                        id: item.CompanyId,
                        name: item.Name,
                        frName: item.Name,
                    };
                })
            );
        }
    }, [allCompanies]);

    const handleDebouncedInput = useMemo(
        () => (search: string) => {
            search
                ? fetcher(`${fetchUrl}?query=${search}`, token).then((data) => {
                      // console.log(data);
                      const filtered = data.map((item: CompanyType) => {
                          return {
                              id: item.CompanyId,
                              name: item.Name,
                              frName: item.Name,
                          };
                      });
                      setFilteredCompanies(filtered);
                  })
                : setFilteredCompanies(companies);
        },
        [token, companies]
    );

    useEffect(() => {
        const timerId = setTimeout(() => {
            handleDebouncedInput(search);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [handleDebouncedInput, search]);

    return {
        companies: search ? filteredCompanies : companies,
        isLoading,
        search,
        setSearch,
    };
};

export default useCompany;
