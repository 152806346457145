import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import { ResultType } from "~/features/Search/Results/types";
// import useDisclosures from "~/hooks/useDisclosures";
import useYears from "~/hooks/useYears";

interface AdvancedSearchProps {
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    activeTabs: ResultType[];
    setActiveTabs: Dispatch<SetStateAction<ResultType[]>>;
    searchTerm: string;
    setSearchTerm: Dispatch<SetStateAction<string>>;
    keyword: string;
    setKeyword: Dispatch<SetStateAction<string>>;
    filters: AdvancedSearchFilterType[];
    setFilters: Dispatch<SetStateAction<AdvancedSearchFilterType[]>>;
    disclosureFilters: FilterType[];
    setDisclosureFilters: Dispatch<SetStateAction<FilterType[]>>;
    yearFilters: FilterType[];
    yearFilterReset: () => void;
    setYearFilters: Dispatch<SetStateAction<FilterType[]>>;
    industryFilters: FilterType[];
    setIndustryFilters: Dispatch<SetStateAction<FilterType[]>>;
    companyFilters: FilterType[];
    setCompanyFilters: Dispatch<SetStateAction<FilterType[]>>;
    imageThemeFilters: FilterType[];
    setImageThemeFilters: Dispatch<SetStateAction<FilterType[]>>;
    pageSectionFilters: FilterType[];
    setPageSectionFilters: Dispatch<SetStateAction<FilterType[]>>;
    sortBy: number;
    setSortBy: Dispatch<SetStateAction<number>>;

    addFilter: (f: FilterType, type: string) => void;
    removeFilter: (f: AdvancedSearchFilterType) => void;
    checkSelected: (type: string, id: number | string) => boolean;
    resetFilters: () => void;

    triggerSearch: boolean;
    setTriggerSearch: (flag: boolean) => void;
}

export interface FilterType {
    id: string | number;
    name: string;
    frName: string;
}

export interface AdvancedSearchFilterType {
    type: string;
    id: string | number;
    value: string;
    frValue: string;
}

export const AdvancedSearchContext = createContext<AdvancedSearchProps>({
    modalOpen: false,
    setModalOpen: () => false,
    activeTabs: [],
    setActiveTabs: () => [],
    searchTerm: "",
    setSearchTerm: () => "",
    keyword: "",
    setKeyword: () => "",
    filters: [],
    setFilters: () => [],
    disclosureFilters: [],
    setDisclosureFilters: () => [],
    yearFilters: [],
    setYearFilters: () => [],
    yearFilterReset: () => [],
    industryFilters: [],
    setIndustryFilters: () => [],
    companyFilters: [],
    setCompanyFilters: () => [],
    imageThemeFilters: [],
    setImageThemeFilters: () => [],
    pageSectionFilters: [],
    setPageSectionFilters: () => [],
    sortBy: 0,
    setSortBy: () => 0,
    addFilter: () => [],
    removeFilter: () => [],
    checkSelected: () => false,
    resetFilters: () => {},
    triggerSearch: false,
    setTriggerSearch: () => [],
});

const AdvancedSearchProvider = ({ children }: { children: ReactNode }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [activeTabs, setActiveTabs] = useState<ResultType[]>(["images"]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [keyword, setKeyword] = useState<string>("");
    const [filters, setFilters] = useState<AdvancedSearchFilterType[]>([]);
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

    // filters
    const [disclosureFilters, setDisclosureFilters] = useState<FilterType[]>([]);
    const [yearFilters, setYearFilters] = useState<FilterType[]>([]);
    const [industryFilters, setIndustryFilters] = useState<FilterType[]>([]);
    const [companyFilters, setCompanyFilters] = useState<FilterType[]>([]);
    const [imageThemeFilters, setImageThemeFilters] = useState<FilterType[]>([]);
    const [pageSectionFilters, setPageSectionFilters] = useState<FilterType[]>([]);
    const [sortBy, setSortBy] = useState<number>(0);

    // hooks
    // const { disclosures } = useDisclosures();
    const { years } = useYears();

    // Set default disclosures and years
    useEffect(() => {
        // resetDisclosures();
        yearFilterReset();
    }, [years]);

    // const resetDisclosures = () => {
    //     setDisclosureFilters(disclosures);
    //     disclosures.forEach((d: FilterType) => {
    //         addFilter(d, "disclosure");
    //     });
    // };

    const yearFilterReset = () => {
        if (years && years.length > 0) {
            const defaultYears: FilterType[] = [];
            defaultYears.push(years[0]);
            if (years.length > 1) defaultYears.push(years[1]);

            setYearFilters(defaultYears);
            defaultYears.forEach((yr) => {
                addFilter(yr, "year");
            });
        }
    };

    const addFilter = (f: FilterType, type: string) => {
        const advfilter = {
            type,
            id: f.id,
            value: f.name,
            frValue: f.frName,
        };
        if (filters.find((x) => x.type === type && x.id === f.id) === undefined) {
            setFilters((filters) => [...filters, advfilter]);
        }
        return;
    };

    const removeFilter = (f: AdvancedSearchFilterType) => {
        const index = filters.findIndex((filter) => filter.type === f.type && filter.id === f.id);
        if (index > -1) {
            const filtersCopy = [...filters];
            filtersCopy.splice(index, 1);
            setFilters(filtersCopy);
            removeFilterByType(f);
        }
    };

    const removeFilterByType = (f: AdvancedSearchFilterType) => {
        switch (f.type) {
            case "disclosure": {
                setDisclosureFilters(disclosureFilters.filter((filter) => filter.id !== f.id));
                return;
            }
            case "year": {
                setYearFilters(yearFilters.filter((filter) => filter.id !== f.id));
                return;
            }
            case "industry": {
                setIndustryFilters(industryFilters.filter((filter) => filter.id !== f.id));
                return;
            }
            case "company": {
                setCompanyFilters(companyFilters.filter((filter) => filter.id !== f.id));
                return;
            }
            case "imagetheme": {
                setImageThemeFilters(imageThemeFilters.filter((filter) => filter.id !== f.id));
                return;
            }
            case "pagesection": {
                setPageSectionFilters(pageSectionFilters.filter((filter) => filter.id !== f.id));
                return;
            }
        }
    };

    const checkSelected = (type: string, id: number | string) =>
        filters.some((f) => {
            if (f.type === type && f.id === id) {
                return true;
            }

            return false;
        });

    const resetFilters = () => {
        setFilters([]);
        setDisclosureFilters([]);
        setYearFilters([]);
        setIndustryFilters([]);
        setCompanyFilters([]);
        setImageThemeFilters([]);
        setPageSectionFilters([]);
        setKeyword("");
        setSearchTerm("");
        setSortBy(0);
    };

    const values = {
        modalOpen,
        setModalOpen,
        activeTabs,
        setActiveTabs,
        searchTerm,
        setSearchTerm,
        keyword,
        setKeyword,
        filters,
        setFilters,
        disclosureFilters,
        setDisclosureFilters,
        yearFilters,
        setYearFilters,
        industryFilters,
        setIndustryFilters,
        companyFilters,
        setCompanyFilters,
        imageThemeFilters,
        setImageThemeFilters,
        pageSectionFilters,
        setPageSectionFilters,
        sortBy,
        setSortBy,
        addFilter,
        removeFilter,
        checkSelected,
        yearFilterReset,
        resetFilters,
        triggerSearch,
        setTriggerSearch,
    };

    return <AdvancedSearchContext.Provider value={values}>{children}</AdvancedSearchContext.Provider>;
};

export default AdvancedSearchProvider;
