import * as React from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "~/providers/LanguageProvider";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { z } from "zod";
import { fetcher } from "~/hooks/useFetcher";
import ChevronRight from "~/ui/Icon/ChevronRight";

const CategorySchema = z.object({
    DocumentTypeID: z.number(),
    Name: z.string(),
    FrName: z.string(),
    Count: z.number(),
});

type CategorySchemaType = z.infer<typeof CategorySchema>;

const Categories: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const token = useReadLocalStorage("_t") as string;
    const { language } = React.useContext(LanguageContext);
    const REGION = import.meta.env.VITE_REGION;

    const { data } = useSWR("/api/market-intelligence/category-count", (url) => fetcher(url, token));

    const renderSkeleton = () => (
        <>
            {[...Array(4)].map((_, index) => (
                <div className="flex justify-center" key={index}>
                    <div className="text-left py-[18px] px-8 title-lg text-foreground-dark w-[734px] max-w-full rounded-full border border-gray-300 flex items-center">
                        <span className="flex-grow w-1/2 h-6 bg-gray-200 animate-pulse rounded-full"></span>
                        <ChevronRight />
                    </div>
                </div>
            ))}
        </>
    );

    return (
        <div className="relative flex flex-col items-center justify-center h-full">
            <h1 className="text-white leading-loose mb-3">{t("marketIntelligenceHeader")}</h1>
            <div className="bg-white rounded-[30px] py-[46px] w-[982px] max-w-full mx-auto grid grid-cols-1 gap-4">
                {!data && renderSkeleton()}
                {data &&
                    data.map((category: CategorySchemaType) => (
                        <CategoryButton
                            key={category.DocumentTypeID}
                            link={category.DocumentTypeID.toString()}
                            label={language === "en" ? category.Name : category.FrName}
                            count={category.Count}
                        />
                    ))}
                {REGION === "fr" && (
                    <CategoryButton
                        key={0}
                        link="https://www.labrador-transparency.com/en/publications/"
                        label={`${t("blogArticles")}`}
                        openTab={true}
                    />
                )}
            </div>
        </div>
    );
};

interface CategoryButtonProps {
    link: string;
    label: string;
    count?: number | null;
    openTab?: boolean;
}

const CategoryButton = (props: CategoryButtonProps) => {
    const { link, count, label, openTab = false } = props;
    return (
        <div className="flex justify-center">
            <Link
                to={`/market-intelligence/${link}`}
                className="text-left py-[18px] px-8 pr-4 text-foreground-dark w-[734px] max-w-full rounded-full border border-foreground-dark flex items-center"
                target={openTab ? "_blank" : "_self"}
            >
                <span className="flex-grow title-md">{label}</span>
                {count && <span className="title-base mr-2">{count}</span>}
                <ChevronRight />
            </Link>
        </div>
    );
};

export default Categories;
