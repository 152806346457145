import { useState, useEffect, useRef, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import i18next from "i18next";
import { useLocalStorage } from "usehooks-ts";
import useSolrSearch from "~/hooks/useSolrSearch";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import { SolrSearchResultDocItem } from "./types";
import NoResult from "../NoResult";
import FullDocumentItem, { FullDocument } from "./FullDocumentItem";

const FullDocument = () => {
    const { language } = i18next;
    const [fullDocuments, setFullDocuments] = useLocalStorage("fullDocuments", "");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [items, setItems] = useState<FullDocument[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const totalItems = useRef(0);
    const refDiv = useRef<HTMLDivElement>(null);
    const [searchTriggered, setSearchTriggered] = useState(false);

    const { searchTerm, modalOpen, triggerSearch, setTriggerSearch } = useContext(AdvancedSearchContext);

    // SolR Search implementation
    const SolRSearchType = `fulldocsearch-fr`;
    const { fetchSearchResults } = useSolrSearch(SolRSearchType);

    const fetchData = async () => {
        try {
            fetchSearchResults(currentPage === 0).then((data: any) => {
                totalItems.current = data.total;
                const newItems = data.items.map(
                    (doc: SolrSearchResultDocItem) =>
                        ({
                            documentID: doc.SourceDocumentID,
                            documentFilePath: doc.SourceDocumentFilePath,
                            documentName: doc.SourceDocumentName,
                            documentType: {
                                en: doc.DocumentTypeUs,
                                fr: doc.DocumentTypeFr,
                            },
                            company: doc.CompanyName,
                            content: doc.Content || "",
                            thumbnail: `/files/thumbnail/${doc.ThumbnailPath}`,
                            year: doc.Year,
                        } as FullDocument)
                );

                if (newItems) {
                    if (currentPage === 0) {
                        setItems(newItems);
                    } else setItems((prevItems) => [...prevItems, ...newItems]);
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const reset = () => {
        if (refDiv && refDiv.current) {
            refDiv.current.scrollTo(0, 0);
        }

        setIsLoading(true);
        setItems([]);
        setCurrentPage(0);
    };

    const getNextData = () => {
        if (!isLoading) setCurrentPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        setSearchTriggered(true);
    }, [currentPage]);

    useEffect(() => {
        if (!modalOpen) {
            reset();
            setSearchTriggered(true);
        } else {
            setCurrentPage(0);
        }
    }, [modalOpen]);

    useEffect(() => {
        reset();
        setTriggerSearch(false);
        setSearchTriggered(true);
    }, [searchTerm, language]);

    useEffect(() => {
        if (triggerSearch) {
            reset();
            setTriggerSearch(false);
            setSearchTriggered(true);
        }
    }, [triggerSearch]);

    useEffect(() => {
        if (searchTriggered) {
            fetchData();
            setSearchTriggered(false);
        }
    }, [searchTriggered]);

    const renderSkeleton = () => {
        return (
            <>
                {[...Array(4)].map((_, index) => {
                    return (
                        <div
                            className="relative rounded-lg shadow-sm py-2 px-3 flex bg-white items-start"
                            key={`doc-item-skeleton-${index}`}
                        >
                            <div className="w-[248px] h-[320px] bg-gray-300 animate-pulse rounded-lg shrink-0"></div>
                            <div className="py-[75px] px-4 xl:px-6 text-sm text-foreground-light w-full">
                                <h3 className="text-[23px] w-72 rounded-md h-7 bg-gray-300 animate-pulse"></h3>
                                <p className="mb-2 w-48 rounded-md h-5 bg-gray-200 animate-pulse mt-4"></p>
                                <p className="mb-6 w-32 rounded-md h-5 bg-gray-200 animate-pulse"></p>
                                <p className="mb-2 w-full rounded-md h-5 bg-gray-200 animate-pulse"></p>
                                <p className="mb-2 w-1/2 rounded-md h-5 bg-gray-200 animate-pulse"></p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    if (isLoading) {
        return <div className="grid grid-cols-2 gap-6 p-6 relative">{renderSkeleton()}</div>;
    }

    return totalItems.current > 0 ? (
        <div id="scrollableDivDocs" ref={refDiv} className="overflow-auto w-full min-w-[1240px] h-full">
            <InfiniteScroll
                dataLength={items.length}
                next={getNextData}
                hasMore={items.length < totalItems.current}
                loader={renderSkeleton()}
                scrollableTarget="scrollableDivDocs"
                className="grid grid-cols-2 w-full gap-4 p-4 xl:gap-6 xl:py-6 xl:px-12"
            >
                {items.map((doc: FullDocument, index: number) => (
                    <FullDocumentItem
                        key={`key-full-doc-index-${index}`}
                        fullDocument={doc}
                        storedDocuments={fullDocuments}
                        setFullDocuments={setFullDocuments}
                    />
                ))}
            </InfiniteScroll>
        </div>
    ) : (
        <NoResult />
    );
};

export default FullDocument;
