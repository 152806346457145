import React, { useEffect, useContext } from "react";
import { LanguageContext } from "~/providers/LanguageProvider";
import { Dialog } from "@headlessui/react";
import { DetailsStore } from "~/stores/DetailsStore";
import { AnimatePresence, motion } from "framer-motion";
//import { Link } from "react-router-dom";
import axios from "axios";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import useAnalytics from "~/hooks/useAnalytics";
import { addBookmark, deleteBookmark } from "~/utils/bookmarkUtils";
import { ResultTypeIndex } from "~/features/Account/Bookmarks/types";
import Button from "~/ui/Button/Button";

const DetailsModal: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { imagePreview } = DetailsStore();
    const HOST = import.meta.env.VITE_API_HOST;
    const token = useReadLocalStorage("_t") as string;
    const storage = import.meta.env.VITE_REGION + "_images";
    const [images, setImages] = useLocalStorage(storage, "");
    const { sp_click } = useAnalytics();
    const bookmarks = images !== "" ? JSON.parse(images) : [];
    const isExist = bookmarks && bookmarks.find((bookmark: any) => bookmark.imageID === imagePreview?.imageID);

    const [isInCart, setIsInCart] = React.useState(false);

    useEffect(() => {
        setIsInCart(isExist);
    }, [imagePreview]);

    const toggleFavorite = () => {
        if (imagePreview) {
            isExist
                ? deleteBookmark(imagePreview.imageID, ResultTypeIndex.Image)
                : addBookmark(imagePreview.imageID, ResultTypeIndex.Image);
        }

        const updatedBookmarks = isExist
            ? bookmarks.filter((bookmark: any) => bookmark.imageID !== imagePreview?.imageID)
            : [...bookmarks, imagePreview];

        setIsInCart(!isExist);
        setImages(JSON.stringify(updatedBookmarks));

        if (!isExist) {
            sp_click(
                "N",
                "bookmark",
                "Image bookmark",
                `${imagePreview?.company} - ${imagePreview?.documentType["en"]} ${imagePreview?.year}, ${imagePreview?.metadata["en"]}`,
                {
                    _title: "Explore Image results",
                }
            );
        }
    };

    const downloadImage = () => {
        sp_click(
            "N",
            "download",
            "Image download",
            `${imagePreview?.company} - ${imagePreview?.documentType["en"]} ${imagePreview?.year}, ${imagePreview?.metadata["en"]}`,
            {
                _title: "Explore Image results",
            }
        );

        return axios
            .get(`${HOST}/api/download/image?imageGuidID=${imagePreview?.imageGuid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", imagePreview?.company + "_" + imagePreview?.imageFilename || "image");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const downloadDocument = () => {
        sp_click(
            "N",
            "download",
            "Image Document download",
            `${imagePreview?.company} - ${imagePreview?.documentType["en"]} ${imagePreview?.year}, ${imagePreview?.metadata["en"]}`,
            {
                _title: "Explore Image results",
            }
        );

        return axios
            .get(`${HOST}/api/download/document?documentGuidID=${imagePreview?.documentGuid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", imagePreview?.pdfFilePath || "pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    return (
        <AnimatePresence>
            {imagePreview && (
                <Dialog
                    className="modal-overlay !pt-8 !pb-8"
                    open={true}
                    onClose={() => DetailsStore.setState((state) => ({ ...state, imagePreview: null }))}
                >
                    <Dialog.Panel
                        as={motion.div}
                        className="modal !grid content-between !p-0 !min-h-[50%] !h-[calc(100%-64px)]"
                        initial={{ y: 24, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 24, opacity: 0 }}
                    >
                        <Dialog.Title className="modal-title pt-4">
                            {imagePreview.company}
                            <Button
                                color="light"
                                icon="close"
                                size="md"
                                elevated
                                className="absolute left-full top-8 translate-x-3 -translate-y-1/2"
                                onPress={() =>
                                    DetailsStore.setState((state) => ({
                                        ...state,
                                        imagePreview: null,
                                    }))
                                }
                            />
                        </Dialog.Title>

                        <div className="h-full overflow-auto">
                            {imagePreview.imageFilePath && (
                                <img
                                    className="object-contain mx-auto w-auto min-w-[50%] p-4"
                                    src={`${HOST}${imagePreview.imageFilePath}`}
                                    alt=""
                                />
                            )}
                        </div>

                        <div className="flex flex-col justify-end">
                            <div className="shrink-0 grid grid-cols-2 py-4 px-8">
                                <div>
                                    <span className="text-sm">Metadata:</span>
                                    <h3>{imagePreview.metadata[language] ? imagePreview.metadata[language] : "--"}</h3>
                                </div>
                                <div>
                                    <span className="text-sm">Image Data:</span>
                                    <h3>{`${imagePreview.documentType[language]} ${imagePreview.year}`}</h3>
                                </div>
                            </div>
                            <div className="w-full shrink-0 py-4 px-8 bg-white overflow-hidden rounded-b-2xl flex justify-between">
                                <div className="flex gap-x-2">
                                    <Button icon="article" size="md" onPress={downloadDocument}>
                                        {t("downloadDocument")}
                                    </Button>

                                    <Button icon="image" size="md" onPress={downloadImage}>
                                        {t("downloadImage")}
                                    </Button>
                                </div>
                                {imagePreview.imageID && (
                                    <Button
                                        color="gradient"
                                        icon="favorite"
                                        iconFilled
                                        size="md"
                                        onPress={() => toggleFavorite()}
                                    >
                                        {t(isInCart ? "removeToBookmarks" : "addToBookmarks")}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            )}
        </AnimatePresence>
    );
};

export default DetailsModal;
