const Search = ({ className }: { className?: string }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M7.6 2C4.51668 2 2 4.51668 2 7.6C2 10.6833 4.51668 13.2 7.6 13.2C8.9984 13.2 10.276 12.6784 11.2594 11.825L11.6 12.1656V13.2L16.4 18L18 16.4L13.2 11.6H12.1656L11.825 11.2594C12.6784 10.276 13.2 8.9984 13.2 7.6C13.2 4.51668 10.6833 2 7.6 2ZM7.6 3.6C9.81861 3.6 11.6 5.38139 11.6 7.6C11.6 9.81861 9.81861 11.6 7.6 11.6C5.38139 11.6 3.6 9.81861 3.6 7.6C3.6 5.38139 5.38139 3.6 7.6 3.6Z"
            fill="#C6C6C6"
        />
    </svg>
);

export default Search;
