import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useIndustry from "~/hooks/useIndustry";
import { AdvancedSearchContext, FilterType } from "~/providers/AdvancedSearchProvider";
import AdvancedSearchFilterBar from "~/features/AdvancedSearch/AdvancedSearchFilterBar";
import FilterToggle from "../ListToggle/FilterToggle";
import ItemList2 from "../ListLayout/ItemList2";

const Industries = () => {
    const FILTER_TYPE = "industry";
    const { t } = useTranslation();
    const { industries, search, setSearch, isLoading } = useIndustry();
    const { industryFilters, setIndustryFilters, filters, setFilters, addFilter, setTriggerSearch } =
        useContext(AdvancedSearchContext);

    // Temp list for changes before update event
    const [tempList, setTempList] = useState<FilterType[]>(industryFilters);
    const enableUpdate =
        tempList.length !== industryFilters.length ||
        !industryFilters.every((f) => tempList.find((tf) => tf.id === f.id));

    const handleUpdate = () => {
        // Update context data when update button is clicked
        if (tempList.length > 0) {
            setIndustryFilters(tempList);

            // Remove items from filters that are not in tempList
            const currFilters = filters.filter((item) =>
                tempList.some(
                    (tempItem) => (tempItem.id === item.id && item.type === FILTER_TYPE) || item.type !== FILTER_TYPE
                )
            );
            setFilters(currFilters);

            tempList.forEach((d: FilterType) => {
                addFilter(d, FILTER_TYPE);
            });
        } else {
            setIndustryFilters([]);
            setFilters(filters.filter((f) => f.type !== FILTER_TYPE));
        }

        setTriggerSearch(true);
    };

    const resetItems = () => {
        setSearch("");
        // setTempList(industryFilters);
    };

    useEffect(() => {
        if (industryFilters.length !== tempList.length) setTempList(industryFilters);
    }, [industryFilters]);

    return (
        <FilterToggle label={t("industry")} count={tempList.length}>
            {({ open }) => (
                <>
                    <AdvancedSearchFilterBar
                        placeholder={`${t("searchCategory2", { category: t("industry").toLowerCase() })}`}
                        search={search}
                        setSearch={setSearch}
                        bgColor="bg-[#F6F3F2]"
                    />
                    <ItemList2
                        label={t("industries")}
                        items={industries}
                        listItems={tempList}
                        setListItems={setTempList}
                        reset={!open}
                        resetFn={resetItems}
                        enableUpdate={enableUpdate}
                        updateFn={handleUpdate}
                        enableSelectAll={false}
                        enableReset={true}
                        groupSelected={true}
                        autoUpdate={true}
                        isLoading={isLoading}
                    />
                </>
            )}
        </FilterToggle>
    );
};

export default Industries;
