import { AdvancedSearchFilterType } from "~/providers/AdvancedSearchProvider";

const convertFiltersForAnalytics = (filters: AdvancedSearchFilterType[], keyword: string | undefined) => {
    const filterVals = filters.map((f) => f.value);
    if (keyword) filterVals.push("keyword: " + keyword);

    return filterVals.join(", ");
};

export { convertFiltersForAnalytics };
