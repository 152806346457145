import axios from "axios";
import { useState, useEffect } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import useAnalytics from "~/hooks/useAnalytics";
import { addBookmark, deleteBookmark } from "~/utils/bookmarkUtils";
import { ResultTypeIndex } from "~/features/Account/Bookmarks/types";
import Button from "~/ui/Button/Button";
import Image from "~/ui/Image/Image";

interface IPageItemProps {
    page: Page;
    storedPages: string;
    setPages: React.Dispatch<React.SetStateAction<string>>;
}

export interface Page {
    bookmarkId: number;
    bookmarkName: string;
    company: string;
    pageNum: number;
    year: number;
    thumbnail: string;
    content: string;
    documentType: string;
    documentName: string;
}

const PageItem: React.FunctionComponent<IPageItemProps> = ({ page, storedPages, setPages }) => {
    const [isInCart, setIsInCart] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const token = useReadLocalStorage("_t") as string;
    const { t } = useTranslation();
    const { sp_click } = useAnalytics();

    useEffect(() => {
        const bookmarks = storedPages !== "" ? JSON.parse(storedPages) : [];
        const match = bookmarks && bookmarks.find((bookmark: Page) => bookmark.bookmarkId === page.bookmarkId);
        const inCart = !!match;

        setIsInCart(inCart);
        setIsFavorite(inCart);
    }, [storedPages]);

    const toggleFavorite = () => {
        // Update local storage
        const bookmarks = storedPages !== "" ? JSON.parse(storedPages) : [];
        const updatedBookmarks = isInCart
            ? bookmarks.filter((bookmark: Page) => bookmark.bookmarkId !== page.bookmarkId)
            : [...bookmarks, page];
        setPages(JSON.stringify(updatedBookmarks));

        // Update server data
        isFavorite
            ? deleteBookmark(page.bookmarkId, ResultTypeIndex.Page)
            : addBookmark(page.bookmarkId, ResultTypeIndex.Page);
        setIsFavorite(!isFavorite);

        if (!isFavorite) {
            sp_click(
                "N",
                "bookmark",
                "Page bookmark",
                `${page.company} - ${page.documentType} ${page.year}, ${page.bookmarkName}, Page ${page.pageNum}`,
                {
                    _title: "Explore Page results",
                }
            );
        }

        return;
    };

    const downloadDocument = () => {
        const region = import.meta.env.VITE_REGION === "us" ? "loadUS" : "loadFR";

        sp_click(
            "N",
            "download",
            "Page download",
            `${page.company} - ${page.documentType} ${page.year}, ${page.bookmarkName}, Page ${page.pageNum}`,
            {
                _title: "Explore Page results",
            }
        );

        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/docpreview/${region}?docbookmarkid=${page.bookmarkId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", page.documentName || "pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const previewDocument = () => {
        const region = import.meta.env.VITE_REGION === "us" ? "loadUS" : "loadFR";
        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/docpreview/${region}?docbookmarkid=${page.bookmarkId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    return (
        <div className="rounded-lg shadow-sm p-4 bg-white w-full flex">
            <div
                className="flex items-center text-left flex-grow"
                // onPress={() => setDetailsData(page)}
            >
                <Image
                    src={`${import.meta.env.VITE_API_HOST}/${page.thumbnail}`}
                    alt={`${page.company} ${page.bookmarkName}`}
                    className="w-[248px] max-h-[320px]"
                />

                <div className="flex-1 px-4 2xl:px-8 py-4 gap-y-4 h-full flex flex-col justify-between">
                    <div>
                        <h2>{page.company}</h2>
                        <h4>
                            {page.documentType}, {page.year}
                        </h4>
                        <p className="text-sm">
                            {page.bookmarkName}, Page {page.pageNum}
                        </p>
                    </div>
                    <div>
                        {page.content && (
                            <p className="text-sm text-foreground-light">
                                {page.content.substring(0, 300)}
                                {page.content.length > 300 ? "..." : ""}
                            </p>
                        )}
                    </div>
                    <div className="flex row mt-2">
                        <Button color="primary" icon="download" className="w-32 2xl:w-44" onPress={downloadDocument}>
                            {t("download")}
                        </Button>
                        <Button color="light" icon="image" className="w-32 2xl:w-44 ml-4" onPress={previewDocument}>
                            {t("preview")}
                        </Button>
                    </div>
                </div>
            </div>
            <Button
                icon="favorite"
                iconFilled
                color="secondary"
                elevated
                noHover
                className={`${isInCart ? "!text-primary" : ""}`}
                onPress={toggleFavorite}
            />
        </div>
    );
};

export default PageItem;
