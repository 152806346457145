import { useContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { fetcher } from "~/hooks/useFetcher";
import { LanguageContext } from "~/providers/LanguageProvider";
import { FilterType } from "~/providers/AdvancedSearchProvider";

export interface IndustryType {
    IndustryID: number;
    Name: string;
    FrName: string;
}

const useIndustry = () => {
    const { language } = useContext(LanguageContext);
    const fetchUrl = `/api/search/filter/industries`;
    const token = useReadLocalStorage("_t") as string;
    const { data: allIndustry, isLoading } = useSWR(fetchUrl, (url) => fetcher(url, token));
    const [industries, setIndustries] = useState<FilterType[]>([]);
    const [search, setSearch] = useState<string>("");
    const [filteredIndustries, setFilteredIndustries] = useState<FilterType[]>(allIndustry);

    useEffect(() => {
        if (allIndustry) {
            setIndustries(
                allIndustry.map((item: IndustryType) => {
                    return {
                        id: item.IndustryID,
                        name: item.Name,
                        frName: item.FrName ? item.FrName : item.Name,
                    };
                })
            );
        }
    }, [allIndustry]);

    const filterBySearch = useMemo(
        () => (search: string) => {
            // const industries: IndustryType[] = allIndustry;
            const filtered = industries.filter((ind: FilterType) => {
                const name = language === "en" ? ind.name : ind.frName;
                return name.toLowerCase().includes(search.toLowerCase());
            });
            setFilteredIndustries(filtered);
        },
        [industries, language]
    );

    const handleDebouncedInput = useMemo(
        () => (search: string) => {
            search ? filterBySearch(search) : setFilteredIndustries(industries);
        },
        [industries, filterBySearch]
    );

    useEffect(() => {
        const timerId = setTimeout(() => {
            handleDebouncedInput(search);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [handleDebouncedInput, search]);

    return {
        industries: search ? filteredIndustries : industries,
        isLoading,
        search,
        setSearch,
    };
};

export default useIndustry;
