import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useYears from "~/hooks/useYears";
import { AdvancedSearchContext, FilterType } from "~/providers/AdvancedSearchProvider";
import FilterToggle from "../ListToggle/FilterToggle";
import ItemList2 from "../ListLayout/ItemList2";

const Years = () => {
    const FILTER_TYPE = "year";
    const { t } = useTranslation();
    const { years, isLoading } = useYears();
    const { yearFilters, setYearFilters, filters, setFilters, addFilter, setTriggerSearch } =
        useContext(AdvancedSearchContext);

    // Temp list for changes before update event
    const [tempList, setTempList] = useState<FilterType[]>(yearFilters);
    const enableUpdate =
        tempList.length !== yearFilters.length || !tempList.every((f) => yearFilters.find((tf) => tf.id === f.id));

    const handleUpdate = () => {
        // Update context data when update button is clicked
        if (tempList.length > 0) {
            setYearFilters(tempList);

            // Remove items from filters that are not in tempList
            const currFilters = filters.filter((item) =>
                tempList.some(
                    (tempItem) => (tempItem.id === item.id && item.type === FILTER_TYPE) || item.type !== FILTER_TYPE
                )
            );
            setFilters(currFilters);

            tempList.forEach((y: FilterType) => {
                addFilter(y, FILTER_TYPE);
            });
        } else {
            setYearFilters([]);
            setFilters(filters.filter((f) => f.type !== "year"));
        }

        setTriggerSearch(true);
    };

    const resetItems = () => setTempList(yearFilters);

    useEffect(() => {
        if (yearFilters.length > 0) setTempList(yearFilters);
    }, [yearFilters]);

    return (
        <FilterToggle label={t("year")} count={tempList.length}>
            {({ open }) => (
                <ItemList2
                    label={`${t("years")}`}
                    items={years}
                    listItems={tempList}
                    setListItems={setTempList}
                    reset={!open}
                    resetFn={resetItems}
                    enableUpdate={enableUpdate}
                    updateFn={handleUpdate}
                    autoUpdate={true}
                    allowNoSelection={true}
                    isLoading={isLoading}
                />
            )}
        </FilterToggle>
    );
};

export default Years;
