import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "~/providers/LanguageProvider";
import { AdvancedSearchContext, FilterType } from "~/providers/AdvancedSearchProvider";
import AdvancedSearchFilterBar from "~/features/AdvancedSearch/AdvancedSearchFilterBar";
import useImageThemes, { ImageThemeListType, ImageThemeMetaDataType, ImageThemeType } from "~/hooks/useImageThemes";
import FilterToggle from "../ListToggle/FilterToggle";
import ExpandableList, { Item, MetaDataItem } from "../ListLayout/ExpandableList";

const ImageThemes = () => {
    const FILTER_TYPE = "imagetheme";
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    const { imageThemes, search, setSearch, isLoading } = useImageThemes();
    const { imageThemeFilters, setImageThemeFilters, filters, setFilters, addFilter, setTriggerSearch } =
        useContext(AdvancedSearchContext);
    const [list, setList] = useState<Item[]>([]);

    // Temp list for changes before update event
    const [tempList, setTempList] = useState<FilterType[]>(imageThemeFilters);
    const enableUpdate =
        tempList.length !== imageThemeFilters.length ||
        !imageThemeFilters.every((f) => tempList.find((tf) => tf.id === f.id));

    // Reset function when popup closes
    const resetItems = () => {
        setSearch("");
        // setTempList(imageThemeFilters);
    };

    const handleUpdate = () => {
        // Update context data when update button is clicked
        if (tempList.length > 0) {
            setImageThemeFilters(tempList);
            // Remove items from filters that are not in tempList
            const currFilters = filters.filter((item) =>
                tempList.some(
                    (tempItem) => (tempItem.id === item.id && item.type === FILTER_TYPE) || item.type !== FILTER_TYPE
                )
            );
            setFilters(currFilters);

            tempList.forEach((d: FilterType) => {
                addFilter(d, FILTER_TYPE);
            });
        } else {
            setImageThemeFilters([]);
            setFilters(filters.filter((f) => f.type !== FILTER_TYPE));
        }

        setTriggerSearch(true);
    };

    const convertToMetaDataItem = (item: ImageThemeMetaDataType) => {
        return {
            id: item.MetadataID,
            name: item.MetadataName,
            frName: item.MetadataNameFr ? item.MetadataNameFr : item.MetadataName,
            categoryId: item.CategoryID,
            documentTypeId: item.DocumentTypeID,
        } as MetaDataItem;
    };

    const convertToItem = (list: ImageThemeListType[]) => {
        const itemList = list.map((category: ImageThemeListType) => {
            const items = category.CategoryChild.map((metadata: ImageThemeMetaDataType) =>
                convertToMetaDataItem(metadata)
            );
            let filtered = null;

            if (search) {
                filtered = items.filter((item: MetaDataItem) => {
                    const name = language === "en" ? item.name : item.frName;
                    return name.toLowerCase().includes(search.toLowerCase());
                });
            }

            return {
                id: category.CategoryId,
                label: {
                    en: category.CategoryName,
                    fr: category.CategoryNameFr,
                },
                items: search ? filtered : items,
            } as Item;
        });

        return itemList.filter((list: Item) => list && list.items.length > 0);
    };

    useEffect(() => {
        const themes = imageThemes.map((theme: ImageThemeType, index: number) => {
            return {
                id: index,
                label: {
                    en: theme.DocumentName,
                    fr: theme.DocumentNameFr,
                },
                items: convertToItem(theme.CategorySections),
            } as Item;
        });
        setList(themes.filter((theme) => theme.items.length > 0));
    }, [imageThemes, search, language]);

    useEffect(() => {
        if (imageThemeFilters.length !== tempList.length) setTempList(imageThemeFilters);
    }, [imageThemeFilters]);

    return (
        <FilterToggle label={t("topic")} count={tempList.length}>
            {({ open }) => (
                <>
                    {!isLoading && (
                        <AdvancedSearchFilterBar
                            placeholder={`${t("searchCategory1", { category: t("topic").toLowerCase() })}`}
                            search={search}
                            setSearch={setSearch}
                            bgColor="bg-gray-100"
                        />
                    )}
                    <ExpandableList
                        label={t("topics")}
                        items={list}
                        listItems={tempList}
                        setListItems={setTempList}
                        reset={!open}
                        resetFn={resetItems}
                        enableUpdate={enableUpdate}
                        enableReset={true}
                        updateFn={handleUpdate}
                        autoUpdate={true}
                        isLoading={isLoading}
                    />
                </>
            )}
        </FilterToggle>
    );
};

export default ImageThemes;
