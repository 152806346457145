import { useContext } from "react";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import { useTranslation } from "react-i18next";
import AppHeader from "~/components/AppHeader";
import SearchTypeTabs from "./SearchTypeTabs";
import FilterList from "./FilterList";

const SearchPanel = () => {
    const { t } = useTranslation();
    const { activeTabs } = useContext(AdvancedSearchContext);

    return (
        <>
            <AppHeader />
            <div className="mx-16 flex flex-col w-[870px] max-w-full my-auto z-[1]">
                <h1 className="text-white text-[43px] leading-[120%] text-center">{t("app_header")}</h1>

                <div className="relative my-12 w-full flex items-center gap-y-4 flex-col">
                    <SearchTypeTabs />
                    <FilterList resultType={activeTabs[0]} />
                </div>
            </div>
        </>
    );
};

export default SearchPanel;
