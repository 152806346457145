import { useState } from "react";
import { Button } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import Icon from "~/ui/Icon/Icon";

const MessageBar = () => {
    const { t } = useTranslation();
    const siteUpdate = import.meta.env.VITE_SITE_UPDATE === "true" || false;
    const [showMessage, setShowMessage] = useState(siteUpdate);

    return (
        <Transition
            show={showMessage}
            as="div"
            className="w-full h-12 bg-black fixed bottom-0 z-20 flex flex-inline justify-center items-center text-white"
            leave="ease-in duration-200"
            leaveFrom="bottom-0"
            leaveTo="-bottom-10"
        >
            <p>{t("appMessage")}</p>
            <Button className="absolute right-2" onPress={() => setShowMessage(false)}>
                <Icon className="hover:text-white">close</Icon>
            </Button>
        </Transition>
    );
};

export default MessageBar;
