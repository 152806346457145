import axios from "axios";
import i18next from "i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import useDisclosures from "./useDisclosures";

export interface ImageThemeType {
    DocumentTypeID: number;
    DocumentName: string;
    DocumentNameFr: string;
    CategorySections: ImageThemeListType[];
}

export interface ImageThemeListType {
    CategoryId: number;
    CategoryName: string;
    CategoryNameFr: string;
    CategoryChild: ImageThemeMetaDataType[];
}

export interface ImageThemeMetaDataType {
    CategoryID: number;
    DocumentTypeID: number;
    MetadataID: number;
    MetadataName: string;
    MetadataNameFr: string;
}

const useImageThemes = () => {
    const { language } = i18next;
    const [imageThemes, setImageThemes] = useState<ImageThemeType[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState<string>("");
    const token = useReadLocalStorage("_t") as string;

    const { disclosures: allDisclosures } = useDisclosures();
    const { disclosureFilters } = useContext(AdvancedSearchContext);
    const disclosures = useMemo(
        () => (disclosureFilters.length > 0 ? disclosureFilters : allDisclosures),
        [disclosureFilters, allDisclosures]
    );

    const fetcher = (url: string, data: object, token: string) =>
        axios.post(`${import.meta.env.VITE_API_HOST}${url}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                Culture: language === "en" ? "en-US" : "fr-FR",
            },
        });

    const fetchData = useCallback(async () => {
        if (disclosures.length > 0) {
            const payload = {
                DisclosureType: disclosures.map((d) => d.id),
                SearchText: "",
            };

            setLoading(true);
            const fetchUrl = `/api/search/filter/image-themes`;
            const { data } = await fetcher(fetchUrl, payload, token);
            setImageThemes(data);
            setLoading(false);
        }
    }, [disclosures, token, language]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        imageThemes,
        isLoading,
        search,
        setSearch,
    };
};

export default useImageThemes;
