import React, { useState } from "react";
// import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { DetailsStore } from "~/stores/DetailsStore";

// interface IDocumentViewerProps {}

const DocumentViewer: React.FunctionComponent = () => {
  const { documentPreview } = DetailsStore();
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // console.log(
  //   `${import.meta.env.VITE_API_HOST}/${documentPreview?.SourceDocumentFilePath}`
  // );

  return (
    <>
      {documentPreview && (
        <div className="fixed inset-0 flex items-center justify-center z-40">
          <div
            className="fixed inset-0 bg-black/30"
            onClick={() => {
              DetailsStore.setState((state) => ({
                ...state,
                documentPreview: null,
              }));
            }}
          ></div>
          <Document
            // file={`${import.meta.env.VITE_API_HOST}${
            //   documentPreview?.SourceDocumentFilePath
            // }`}
            file="http://add-explore-uat-fr.labrador-company.com/Files/PDF/45f04efd-7cdb-414b-9d1d-66468ff6ac0c.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </div>
      )}
    </>
  );
};

export default DocumentViewer;
