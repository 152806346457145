import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";

const FilterKeyword = () => {
    const { t } = useTranslation();

    const { searchTerm, setSearchTerm } = useContext(AdvancedSearchContext);
    const [textValue, setTextValue] = useState(searchTerm);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchTerm(textValue);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [textValue]);

    return (
        <div className="relative flex grow p-1">
            <input
                type="text"
                placeholder={`${t("typeKeywordMsg")}`}
                className="pl-2 flex-grow focus-visible:outline-none bg-transparent text-black"
                value={textValue}
                onChange={handleInputChange}
                required
            />
        </div>
    );
};

export default FilterKeyword;
