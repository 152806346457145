import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import Button from "~/ui/Button/Button";
import Icon from "~/ui/Icon/Icon";

interface SearchButtonProps {
    className?: string;
    disabled: boolean;
}

const SearchButton = (props: SearchButtonProps) => {
    const { searchTerm } = useContext(AdvancedSearchContext);
    const navigate = useNavigate();

    return (
        <Button
            type="submit"
            noHover
            color="primary"
            size="md"
            className={`btn-search ${props.className}`}
            onPress={() => {
                if (!props.disabled) {
                    if (searchTerm) {
                        navigate(`/search/result?q=${searchTerm}`);
                    } else {
                        navigate(`/search/result`);
                    }
                }
            }}
        >
            <Icon className="!text-white !text-lg">search</Icon>
        </Button>
    );
};

export default SearchButton;
